import Icon, { SettingOutlined, ShoppingCartOutlined, UserOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { ReactComponent as dashboardIcon } from '../assets/icons/ic_dashboard.svg';
import { ReactComponent as storeIcon } from '../assets/icons/ic_store.svg';
import { ReactComponent as analyticsIcon } from '../assets/icons/ic_analytics.svg';
import { ReactComponent as promotionIcon } from '../assets/icons/ic_promotion.svg';
import { ReactComponent as ingredientIcon } from '../assets/icons/ic_ingredient.svg';
import { ReactComponent as bestdealIcon } from '../assets/icons/ic_bestdeal.svg';
import { ReactComponent as productIcon } from '../assets/icons/ic_product.svg';
import { ReactComponent as postIcon } from '../assets/icons/ic_post.svg';
import { ReactComponent as categoryIcon } from '../assets/icons/ic_category.svg';
import { ReactComponent as locationIcon } from '../assets/icons/ic_location.svg';
import { ReactComponent as unitIcon } from '../assets/icons/ic_unit.svg';

export const ROOT = "/";
export const ROUTE_DASHBOARD = "/dashboard";

export const ROUTE_LOGIN = "/login";
export const ROUTE_REGISTER = "/register";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";

export const ROUTE_TRAFFIC = "/traffic";
export const ROUTE_PROFILE = "/profile";
export const ROUTE_NOTIFICATION = "/notification";
export const ROUTE_DEVICE = "/device";
export const ROUTE_REPORT = "/report";

export const MENU_ITEMS = [
    { route: ROUTE_DASHBOARD, key: ROUTE_DASHBOARD, icon: <Icon component={dashboardIcon} className="menu-item-icon" />, label: "Overview", type:"sidebar" },
    { route: ROUTE_TRAFFIC, key: ROUTE_TRAFFIC, icon: <Icon component={storeIcon} className="menu-item-icon" />, label: "Traffic Info List", type:"sidebar"},
    { route: ROUTE_NOTIFICATION, key: ROUTE_NOTIFICATION, icon: <Icon component={promotionIcon} className="menu-item-icon" />, label: "Notifications", type:"sidebar"},
    { route: ROUTE_DEVICE, key: ROUTE_DEVICE, icon: <Icon component={postIcon} className="menu-item-icon" />, label: "Device List", type:"sidebar"},
    { route: ROUTE_REPORT, key: ROUTE_REPORT, icon: <Icon component={productIcon} className="menu-item-icon" />, label: "Report", type:"sidebar"},
    { route: ROUTE_PROFILE, key: ROUTE_PROFILE, icon: <Icon component={analyticsIcon} className="menu-item-icon" />, label: "Profile Setting", type:"sidebar"},
]
