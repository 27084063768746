import React from 'react';
import "./TopWidget.css"
import { Row, Col } from "antd";

import { ReactComponent as storeIcon } from '../../../assets/icons/ic_store.svg';
import { ReactComponent as promotionIcon } from '../../../assets/icons/ic_promotion.svg';
import { ReactComponent as productIcon } from '../../../assets/icons/ic_product.svg';
import { ReactComponent as followerIcon } from '../../../assets/icons/ic_follower.svg';
import CategoryItemWidget from './CategoryItemWidget/CategoryItemWidget';
import { useNavigate } from "react-router-dom";
import { ROUTE_NOTIFICATION, ROUTE_PROFILE,  ROUTE_TRAFFIC } from 'navigation/CONSTANTS';
import { useDispatch } from 'react-redux';
import { UpdateCurrentRoot } from "../../../redux/actions/appActions";

const TopWidget = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <div>
            <Row align={'middle'} gutter={32}>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <CategoryItemWidget
                        icon={storeIcon}
                        title="You will manage total traffic test Info"
                        count={''}
                        type="Traffic Info List"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_TRAFFIC}))
                            navigate(ROUTE_TRAFFIC)
                        }}
                    />
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <CategoryItemWidget
                        icon={promotionIcon}
                        title="You can create new Notification to all user"
                        count={''}
                        type="Notifications"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_NOTIFICATION}))
                            navigate(ROUTE_NOTIFICATION)
                        }}
                    />
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <CategoryItemWidget
                        icon={followerIcon}
                        title="Super Admin Setup"
                        count={''}
                        type="Profile"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_PROFILE}))
                            navigate(ROUTE_PROFILE)
                        }}
                    />
                </Col>

            </Row>
        </div>
    );
};

export default TopWidget;