
function ForgotPasswordPage(props) {
  return (
  <div>
  ForgotPasswordPage
  </div>
  )
}

export default ForgotPasswordPage;
