import React, { useState } from 'react'
import { Table, Select } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
const { Option } = Select;

function TableWidget(props) {
    const { dataList, setDataList, onUpdateAction, onDeleteAction, } = props;
    if (dataList === undefined) return <div />

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Email",
            dataIndex: "user_email",
            align: "center",
            sorter: {
                compare: (a, b) => a.user_email.localeCompare(b.user_email),
            },
        },
        {
            title: "IP",
            dataIndex: "ip",
            align: "center",
            sorter: {
                compare: (a, b) => a.ip.localeCompare(b.ip),
            },
        },
        {
            title: "Browser",
            dataIndex: "browser_info",
            align: "center",
            sorter: {
                compare: (a, b) => a.browser_info.localeCompare(b.browser_info),
            },
        },
        {
            title: "Created At",
            dataIndex: "add_timestamp",
            align: "center",
            sorter: {
                compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
            },
        }
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default TableWidget
