import React, { useState } from 'react'
import { Table, Select, InputNumber } from "antd";

function TableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, setDataList} = props;


    if (dataList === undefined) return <div />

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Name",
            dataIndex: "name",
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
            },
        },
        {
            title: "Url",
            dataIndex: "url",
            sorter: {
                compare: (a, b) => a.url.localeCompare(b.url),
            },
        },
        {
            title: "Device UUID",
            dataIndex: "device_uuid",
            sorter: {
                compare: (a, b) => a.device_uuid.localeCompare(b.device_uuid),
            },
        },
        {
            title: "Internet",
            dataIndex: "connection_type",
            sorter: {
                compare: (a, b) => a.connection_type.localeCompare(b.connection_type),
            },
        },
        {
            title: "Created At",
            dataIndex: "add_timestamp",
            sorter: {
                compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default TableWidget
