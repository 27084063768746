import { UPDATE_FEED_LIST } from "../../redux/actions/feedActions";

const initialState = { feedList: [] };

export const feedReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FEED_LIST:
      return {
        ...state,
        feedList: [...action.payload],
      };
    default:
      return state;
  }
};
