import React from 'react';
import "./MainLayout.css";
import { Outlet } from 'react-router-dom';
const MainLayout = (props) => {
    return (
        <div className="main-page">
            <Outlet/>
        </div>
    );
};

export default MainLayout;